<template>
  <OrganizationAccountsAndUsers
    :canAssignClinic="false"
    clinicEditRoute="OrgOwnerClinicEdit"
    nurseEditRoute="OrgOwnerNurseDetails"
    doctorEditRoute="OrgOwnerDoctorEdit"
    doctorClinicEditRoute="OrgOwnerDoctorClinicsEdit"
    dermaSpecialistEditRoute="OrgOwnerDermaSpecialistEdit"
    basicEditRoute="OrgOwnerBasicEditRoute"
    :inOrgOwner="true"
  />
</template>


<script>
import OrganizationAccountsAndUsers from "./organizationAccountsAndUsers.vue";

export default {
  components: {
    OrganizationAccountsAndUsers,
  },
  data: () => ({}),
};
</script>
